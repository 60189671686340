@import 'colors';

.btn {
    display: inline-block;
    padding: .75em 1.5em;
    margin-top: 1em;
    border: .2em solid;
    text-decoration: none;
    font-weight: 400;
    font-size: 0.85em;
    text-align: center;
    transition: all .25s;
    font-style: normal;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btn-primary {
    background-color: $primary;
    border-color: $white;
    color: $white;

    &:hover {
        background-color: $white;
        color: $black;
    }
}

.btn-secondary {
    background-color: $primary;
    border-color: #fff;
    color: #fff;

    &:hover {
        background-color: transparent;
    }
}