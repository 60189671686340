@import 'colors';

.iconfields {
    color: $primary;
    display: grid;
    gap: 3em;
}

.iconfield {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1em 0;

    .iconfield-wrap {
        display: flex;
        flex-direction: column;

        .iconfield-header {
            display: flex;
            margin: 1em 0;
            
            .iconfield-icon {
                font-size: 1.5rem;
                margin-left: 0;
                margin-right: 1em;
            }
        
        }
    }

    p {
        color: $black;
        text-align: justify;
        margin: 1em 0;
    }
    a {
        color: $primary;
    }

    .iconfield-link-wrapper {
        display: block;
    }
}

@media screen and (max-width: 960px) {
    .iconfields.mobile {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-device-width: 960px) {
    .iconfields.mobile {
        display: flex;
        flex-direction: column;
    }
}