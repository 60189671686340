@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import 'colors';

html, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

section {
    padding: 3em 1em;
}

.container {
    max-width: 1200px;
    margin: auto;
}

body {
    font-family: 'Lato', sans-serif;
}

a, Link {
    text-decoration: none;
    font-style: italic;
    cursor: pointer;
}

h1 {
    display: block;
    font-size: 3rem;
    padding: 1em 1em;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    margin: 1em 0;
    
}

h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

h5 {
    font-size: 1rem;
    color: $primary;
}

h6 {
    font-size: 1rem;
    margin: 0.5em;
}

p {
    font-family: 'Open Sans', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

.primary {
    color: $primary;
}
.black {
    color: $black;
}
.white {
    color: $white;
}
