@import '_colors';

// .navbar {
//     font-size: 1.2rem;
//     background-color: $white;
//     height: 80px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .navbar-logo {
//     color: $primary;
//     justify-self: start;
//     margin-left: 1em;
//     cursor: pointer;
//     width: 50px;
//     height: 50px;
// }

// .navbar-icons {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     max-height: 60px;
    
//     a {
//         text-decoration: none;
//         color: inherit;
//     }

//     i {
//         padding: 0 1em;
//     }
// }

// .fa-bars, .fa-times {
//     margin-left: 0.5em;
//     font-size: 1esm;
// }

// .navbar-menu {
//     display: flex;
//     list-style: none;
//     text-align: center;
//     width: 100%;
//     justify-content: end;
// }

// .navbar-link {
//     color: $black;
//     padding: 0.5rem 1rem;

//     &:hover {
//         background: $primary;
//         border-radius: 4px;
//         transition: all 0.2s ease-out;
//     }
// }

// @media screen and (max-width: px) {
//     .navbar {
//         position: relative;
//     }

//     .navbar-menu {
//         display: flex;
//         flex-direction: column;
//         position: absolute;
//         padding: 0;
//         top: 60px;
//         left: -100px;
//         width: 100%;
//         opacity: 0;
//         transition: all 0.5s ease;
//     }

//     .navbar-menu-active {
//         background-color: lighten($primary, 20);
//         left: 0;
//         opacity: 1;
//         transition: all 0.5s ease;
//         z-index: 1;

//         li {
//             margin: 1em;
//         }
//     }

//     .navbar-link {
//         text-align: center;
//         margin: 3rem 0;

//         &:hover {
//             background: lighten($primary, 40);
//             border-radius: 4px;
//             border-color: lighten($primary, 40)
//         }
//     }

//     .navbar-logo {
//         position: absolute;
//         top: 0;
//         left: 0;
//         transform: translate(25%, 50%);
//     }

//     .navbar-menu-icon {
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         transform: translate(-100%, 60%);
//         font-size: 1.8rem;
//         cursor: pointer;
//     }
// }

// .wrap {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
// }

header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    box-shadow: 5px 2px 30px grey;
    z-index: 1;
}

nav {
    height: 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-logo {
        margin-left: 1rem;
        width: 75px;
        height: 75px;
        max-width: 100%;
        height: auto;
    }

    .nav-toggler {
        margin-right: 2rem;
        width: 25px;
        height: 25px;
        display: block;
        cursor: pointer;
        color: $black;
        text-align: center;

        i {
            font-size: 2em;
        }
    }

    .nav-list {
        display: flex;
        padding: 1.5em;
        list-style: none;

        li {
            padding: 1em;
            color: $black;

            a .fab {
                font-size: 1.5em;
            }
        }
    }
    
    .nav-link {
        position: relative;
        text-decoration: none;
        font-style: italic;
        color: $black;
        text-decoration: none;
        
        &:hover:after {
            border-color: $primary;
            right: 0;
        }

        &:after {
            border-radius: 1em;
            border-top: .2em solid #fff;
            content: "";
            position: absolute;
            right: 100%;
            bottom: -.5em;
            left: 0;
            transition: right .4s cubic-bezier(0,.5,0,1),
            border-color .4s ease-out;
        
            
        }

        
    }
}

@media screen and (max-width: 960px) {

    .nav-collapse {
        .nav-list {
            display: flex;
            flex-direction: column;
        }
        text-align: center;
        width: 100%;
        position: absolute;
        top: 100px;
        left:-100px;
        opacity: 0;
        background-color: lighten($primary, 30);
        transition: all 0.5s ease;

        .nav-list li a {
            display: none;
        }
    }

    .nav-collapse.active {
        
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;

        .nav-list li a {
            display: block;
            width: 100%;
        }
    }

    
}