@import 'colors';

footer {
    padding: 3em 1em;
    background-color: $primary;
    color: $white;
    width: 100%;
    min-height: 100px;
    overflow: hidden;

    .footer-copyright{
        text-align: center;
        margin-top: 3em;
        font-size: 0.8rem;
    }

    .footer-link {
        font-size: 2em;
        color: $white;
    }
} 