@import '../../styles/colors';

.team-member-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2em;
    text-align: center;
    margin: 3em 0;
}

.team-member {
    display: flex;
    flex-direction: column;

    &-hr {
        width: 30%;
        position: relative;
        border-top: 3px solid $primary;
        
        margin: auto;
        margin-top: 1em;
    }

    &-name {
        margin-top: 1em;
    }

    &-position {
        margin-top: .5em;
        margin-bottom: 2em;
        font-size: .75rem;
    }

    .fa.fa-linkedin {
        color: $black;
        font-size: 2em;
    }
}